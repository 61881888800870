import { useQuery } from 'react-query';

import { getAllTags, getFrontPage } from '../../../api';
import { Loader } from '../../../components';
import Frontpage from '../components/frontpage';
import { ComposeMeta } from '../../../helpers/compose-meta';

const PublicContainer: React.FC = () => {
    const { isLoading, data } = useQuery<IFrontpage, Error>(
        'frontpage',
        getFrontPage
    );
    const { data: tags } = useQuery<ITag[], Error>('getAllTags', getAllTags);
    if (isLoading || !data) return <Loader />;
    return (
        <>
            <ComposeMeta
                data={{
                    title: 'Explore',
                }}
            />

            <Frontpage
                tags={tags}
                featured={data.featured}
                playbooks={data.playbooks}
                plays={data.plays}
            />
        </>
    );
};

export default PublicContainer;

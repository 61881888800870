import React from 'react';
import { Helmet } from 'react-helmet';
interface Props {
    data?: Partial<IPlay> | Partial<IPlaybook>;
}

export const ComposeMeta: React.FC<Props> = ({ data }) => {
    const orgName = data?.author?.orgName ? `for ${data?.author?.orgName}` : '';

    return (
        <Helmet>
            <title>{data?.title}</title>
            {data?.author && (
                <meta
                    name="author"
                    content={`${data?.author.fullName} ${orgName}`}
                />
            )}
            <meta
                property="og:title"
                content={`${data?.title} | Founders Playbook`}
            />
        </Helmet>
    );
};

import { useAuth0 } from '@auth0/auth0-react';
import { Icon } from '@founders-playbook/component-library/src';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled, { keyframes, useTheme } from 'styled-components';
import { Box, Button } from '../../../components';
import { getTheme, getStyle } from '../../../helpers';
import { useWindowSize } from '../../../hooks';
import underline from './underline.svg';

interface Props {}

const underlineAnimation = keyframes`
    from {background-size:0 120%}
    to {background-size:120% 120%}
`;

const BrandButton = styled.button`
    color: white;
    font-family: ${getTheme('fonts.heading')};
    font-weight: ${getTheme('btn.base.fontWeight')};
    ${getStyle('btn.base.fontSize')};
    line-height: ${getTheme('btn.base.lineHeight')};
    border-radius: ${getTheme('radii.5')};
    padding: ${getTheme('btn.base.padding')};
    ${getStyle('btn.base.padding')};
    outline: ${getTheme('btn.base.outline')};
    border: ${getTheme('btn.base.border')};
    cursor: ${getTheme('btn.base.cursor')};

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.15s ease;
    &:hover {
        transform: translate(5px, -5px);
    }

    background-color: #ff6534;
    border: ${getTheme('btn.primary.default.border')};

    &:focus {
        box-shadow: 0 0 0 2px #ff6534 inset, 0 0 0 4px white inset;
        border: none;
    }
    &:active:enabled {
        background-color: #e64c1b;
    }
`;

const Link = styled.a<{ active?: boolean }>`
    position: relative;
    font-family: ${getTheme('fonts.heading')};
    font-size: 16px;
    text-decoration: none;
    color: ${({ active }) =>
        active ? '#FF6534' : getTheme('colors.grey.100')};
    cursor: pointer;
    margin-left: 36px;
    margin-top: 24px;

    &:hover:after {
        animation: ${underlineAnimation} 0.7s cubic-bezier(0.5, 0.15, 0, 1);
        background-image: url(${underline});
    }

    &:after {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        height: 15px;
        background-repeat: no-repeat;
        background-size: 120% 120%;
        content: '';
    }

    @media (min-width: 768px) {
        margin-top: 0;
        font-size: 18px;
    }
`;

const MOBILE_SCREEN_SIZE = 768;

export const Navigation: React.FC<Props> = () => {
    const { t } = useTranslation();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const theme = useTheme();
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { width } = useWindowSize();

    let button;
    if (isAuthenticated) {
        button = (
            <Box
                display="inline-block"
                borderRadius="999px"
                position="relative"
                boxShadow="inset 0 0 0 1.5px #ff6534"
            >
                <BrandButton onClick={() => history.push('/dashboard')}>
                    {t('explore.header.toDashboardAction')}
                </BrandButton>
            </Box>
        );
    } else {
        button = (
            <Box
                display="inline-block"
                borderRadius="999px"
                position="relative"
                boxShadow="inset 0 0 0 1.5px #ff6534"
            >
                <BrandButton onClick={loginWithRedirect}>
                    {t('explore.header.signInAction')}
                </BrandButton>
            </Box>
        );
    }

    return (
        <>
            <Box
                zIndex={3}
                position="relative"
                px={[6, 6, 8]}
                py={6}
                backgroundColor={getTheme(`colors.white.100`)(theme)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={['100%', '480px', '576px', '768px', '1024px', '1440px']}
                mx="auto"
            >
                <Button
                    display={['block', 'block', 'block', 'none']}
                    variant="icon"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <Icon iconName="menu" />
                </Button>

                <Box display="flex" alignItems="center">
                    <a href="https://www.founders-playbook.com/">
                        <svg
                            width="35"
                            height="43"
                            viewBox="0 0 35 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.1168 29.5565C20.0783 29.5565 25.7217 23.9131 25.7217 16.9515H0.51183V16.9516L0.511719 16.9516L0.511719 29.5565C0.511718 36.518 6.15515 42.1615 13.1167 42.1615L13.1167 29.5565H13.1168Z"
                                fill="#FF6534"
                            />
                            <g>
                                <path
                                    d="M0.511719 8.4033C0.511719 3.76229 4.27401 0 8.91502 0H34.1249C34.1249 6.96152 28.4815 12.6049 21.52 12.6049H0.511719V8.4033Z"
                                    fill="#00B0DB"
                                />
                            </g>
                        </svg>
                    </a>
                    <Box display={['none', 'none', 'none', 'inline-block']}>
                        <Link active>Explore</Link>
                        <Link href="https://www.founders-playbook.com/pricing">
                            Pricing
                        </Link>
                        <Link href="https://www.founders-playbook.com/about">
                            About
                        </Link>
                    </Box>
                </Box>

                <Box>{button}</Box>
            </Box>

            {width && width < MOBILE_SCREEN_SIZE && isMenuOpen && <MobileNav />}
        </>
    );
};

const MobileNav = () => (
    <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="white.100"
        zIndex={2}
    >
        <Box display="flex" flexDirection="column" marginTop="80px">
            <Link active>Explore</Link>
            <Link href="https://www.founders-playbook.com/pricing">
                Pricing
            </Link>
            <Link href="https://www.founders-playbook.com/about">About</Link>
        </Box>
    </Box>
);

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Box, Checkbox, FakeLink, Heading, Text } from '../../../components';
import { getTheme } from '../../../helpers';

interface Props {
    tags?: ITag[];
    selectedTags: IChildTag[];
    handleSelectedTag: (tag: IChildTag) => void;
    resetFilters: () => void;
}

export const SideBar: React.FC<Props> = ({
    tags,
    selectedTags,
    handleSelectedTag,
    resetFilters,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!tags) return null;

    return (
        <Box pt={11} pb={9} pl={8}>
            <Box display="flex">
                <Heading variant="lg" mr={6}>
                    {t('explore.sidebar.filter.title')}
                </Heading>
                {selectedTags.length > 0 && (
                    <FakeLink underline={true} onClick={resetFilters}>
                        {t('explore.sidebar.filter.resetAction')}
                    </FakeLink>
                )}
            </Box>

            {tags.map(parentTag => {
                return (
                    <Box mt={8} key={parentTag._id}>
                        <Text
                            color={getTheme(`colors.silver.500`)(theme)}
                            fontWeight={500}
                        >
                            {parentTag.name}
                        </Text>
                        <Box>
                            {parentTag.tags &&
                                parentTag.tags.map(tag => {
                                    return (
                                        <Box key={tag._id} mt={5}>
                                            <Checkbox
                                                onChange={() =>
                                                    handleSelectedTag(tag)
                                                }
                                                name={tag.name}
                                                checked={
                                                    !!selectedTags?.find(
                                                        ({ _id }) =>
                                                            _id === tag._id
                                                    )
                                                }
                                            />
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

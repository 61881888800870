import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '../../../components';
import { contentWidth } from '../../../helpers';
import headerBg from './header-bg.svg';

export const Header: React.FC<{ search: React.ReactElement }> = ({
    search,
}) => {
    const { t } = useTranslation();

    return (
        <Box
            pt={[8, 9, 9, 9, 10, 11]}
            pb={[9, 10, 10, 10, 11, 12]}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                backgroundImage: `url(${headerBg})`,
                backgroundSize: 'cover',
            }}
        >
            <Box
                mx={[6, 'auto']}
                width={[
                    contentWidth[0],
                    contentWidth[1],
                    contentWidth[2],
                    '544px',
                    '675px',
                ]}
            >
                <Heading variant="xxl" color="white.100" textAlign="center">
                    {t('explore.header.title')}
                </Heading>
            </Box>

            <Box
                bottom="0"
                left="50%"
                position="absolute"
                style={{ transform: 'translateX(-50%)' }}
            >
                <Box
                    position="absolute"
                    style={{ transform: 'translate(-50%, -50%)' }}
                >
                    {search}
                </Box>
            </Box>
        </Box>
    );
};

import axios from 'axios';

import { API_BASE_URL } from '../constants/api';

export const getSearchResults = (query: string): Promise<any[]> =>
    axios
        .get(`${API_BASE_URL}/public/search?q=${query}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

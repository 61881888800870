import { Icon } from '@founders-playbook/component-library';
import { debounce } from 'lodash';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../box';
import { Input } from '../input';

interface Props {
    query: string;
    setQuery: Dispatch<SetStateAction<string>>;
}

const Search: React.FC<Props> = ({ query, setQuery }) => {
    const { t } = useTranslation();
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setQuery(query);
    };
    const [inputQuery, setInputQuery] = useState(query);
    const debouncedApiSearch = useCallback(
        debounce(text => setQuery(text), 500),
        []
    );
    useEffect(() => {
        debouncedApiSearch(inputQuery);
    }, [inputQuery, debouncedApiSearch]);

    return (
        <Box position="relative" boxShadow="highHover" borderRadius="100px">
            <form onSubmit={onSubmit}>
                <Input
                    variant="xl"
                    borderRadius="100px"
                    py={6}
                    pl={7}
                    pr={10}
                    placeholder={t('explore.header.searchPlaceholder')}
                    type="text"
                    name="search"
                    value={inputQuery}
                    onChange={e => setInputQuery(e.target.value)}
                    omitDefaultPadding
                />
            </form>
            <Box
                top={3}
                bottom={3}
                right={3}
                position="absolute"
                bg="gold.500"
                width={['50px', '50px', '50px', '54px']}
                height={['50px', '50px', '50px', '54px']}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
            >
                <Icon iconName="search"></Icon>
            </Box>
        </Box>
    );
};

export default Search;

import { Icon } from '@founders-playbook/component-library';
import qs from 'qs';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router';
import { getSearchResults } from '../../../api/search';
import { Box, Button, Overlay, Text } from '../../../components';
import Search from '../../../components/search';
import { TileCard } from '../../../components/tile/tile-card';
import {
    contentWidth,
    getContentType,
    getPlainTextFromRaw,
} from '../../../helpers';
import {
    playToTileProps,
    playbookToTileProps,
    getLink,
} from '../../../helpers/content';
import { usePageSidebar, useWindowSize } from '../../../hooks';
import { PageContext } from '../../../providers';
import { Header } from './header';
import { Navigation } from './navigation';
import { SideBar } from './sidebar';

type Props = {
    tags: ITag[] | undefined;
    featured: IPlaybook;
    playbooks: IPlaybook[];
    plays: IPlay[];
};

const Frontpage: React.FC<Props> = ({ plays, playbooks, tags }) => {
    const [sidebarOpen] = useContext(PageContext);
    const { width } = useWindowSize();

    const { t } = useTranslation();
    const { q, ...rest } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    const { toggleSidebar } = usePageSidebar();

    const initFilters = rest['filter.tag'] as string[];

    const childTags = tags?.flatMap(({ tags }) => tags);

    const initTags = childTags?.filter(
        tag => initFilters && tag && initFilters.includes(tag._key)
    );

    const retainedQuery: string = !!q ? (q as string) : '';

    const [query, setQuery] = useState<string>(retainedQuery);
    const { path } = useRouteMatch();
    const history = useHistory();

    const [selectedTags, setSelectedTags] = useState<IChildTag[]>(
        initTags ?? []
    );

    const handleSelectedTag = (tag: IChildTag) => {
        const isSelected = !!!selectedTags?.find(({ _id }) => _id === tag._id);
        const tags = selectedTags ? selectedTags : [];
        if (isSelected) {
            setSelectedTags([...tags, tag]);
        } else {
            const filtered = tags.filter(({ _id }) => _id !== tag._id);
            setSelectedTags(filtered);
        }
    };

    const resetFilters = () => {
        setSelectedTags([]);
    };

    const tagQuery = selectedTags?.reduce((a, b) => {
        if (!a) return `&filter.tag=${b._key}`;
        return `${a}&filter.tag=${b._key}`;
    }, '');

    const queryString = tagQuery?.length ? query + `${tagQuery}` : query;

    const { data: results } = useQuery<IPlaybook[] | IPlay[], Error>(
        [`getSearch${queryString}`],
        () => getSearchResults(queryString),
        {
            enabled: !!queryString,
        }
    );

    const content = queryString ? results : [...plays, ...playbooks];

    useEffect(() => {
        if (queryString) {
            history.replace(`${path}/?q=${queryString}`);
        }
        if (!queryString) {
            history.replace(`${path}`);
        }
    }, [queryString, history, path]);

    const PageHeader = () => (
        <>
            <Navigation />
            <Box position="relative" zIndex={1}>
                <Header
                    search={
                        <Box
                            width={[
                                '288px',
                                contentWidth[1],
                                contentWidth[2],
                                '544px',
                                '675px',
                            ]}
                            mx="auto"
                        >
                            <Search query={query} setQuery={setQuery} />
                        </Box>
                    }
                />
            </Box>
        </>
    );

    return (
        <Box
            overflowY={sidebarOpen && width && width < 1024 ? 'hidden' : 'auto'}
            position="relative"
        >
            <PageHeader />

            <Box display="flex" bg="silver.25">
                {sidebarOpen && (
                    <Box
                        position={[
                            'fixed',
                            'fixed',
                            'fixed',
                            'fixed',
                            'sticky',
                        ]}
                        top="0"
                        bottom="0"
                        alignSelf="flex-start"
                        bg={[
                            'white.100',
                            'white.100',
                            'white.100',
                            'white.100',
                            'transparent',
                        ]}
                        zIndex={10}
                        overflowY="auto"
                        width="296px"
                    >
                        <SideBar
                            resetFilters={resetFilters}
                            tags={tags}
                            selectedTags={selectedTags}
                            handleSelectedTag={handleSelectedTag}
                        />

                        <Box
                            position="absolute"
                            top={6}
                            right={6}
                            display={[
                                'block',
                                'block',
                                'block',
                                'block',
                                'none',
                            ]}
                        >
                            <Button variant="icon" onClick={toggleSidebar}>
                                <Icon iconName="x" />
                            </Button>
                        </Box>
                    </Box>
                )}

                <Box
                    width={[
                        '100%',
                        '432px',
                        '480px',
                        '656px',
                        '656px',
                        '1040px',
                    ]}
                    px={[6, 0]}
                    mx="auto"
                    pt={10}
                >
                    {sidebarOpen && width && width < 1024 && (
                        <Overlay position="fixed" onClick={toggleSidebar} />
                    )}

                    <Button
                        variant="secondary"
                        onClick={toggleSidebar}
                        display={['block', 'block', 'block', 'block', 'none']}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon iconName="adjustments"></Icon>
                            <Text ml={6} variant="sm">
                                {t('explore.main.openSidebarAction')}
                            </Text>
                        </Box>
                    </Button>

                    {queryString && results && (
                        <Text color="silver.600">
                            {t('explore.main.searchResultsCount', {
                                count: results?.length,
                            })}
                        </Text>
                    )}

                    <Box mb={8}>
                        {content && (
                            <Box
                                mx={[0, 0, 0, 0, 0, '-16px']}
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="space-between"
                                flexDirection={[
                                    'column',
                                    'column',
                                    'column',
                                    'column',
                                    'column',
                                    'row',
                                ]}
                            >
                                {content?.map(content => (
                                    <Box
                                        width={[
                                            '100%',
                                            '100%',
                                            '100%',
                                            '100%',
                                            '100%',
                                            '50%',
                                        ]}
                                        mt={[6, 7, 7, 8]}
                                        px={[0, 0, 0, 0, 0, 6]}
                                        key={content._key}
                                    >
                                        <TileCard
                                            author={content.author}
                                            type={getContentType(content._id)}
                                            secondaryText={getPlainTextFromRaw(
                                                content.goal
                                            )}
                                            tileProps={{
                                                link: getLink(
                                                    content,
                                                    'published'
                                                ),
                                                ...(getContentType(
                                                    content._id
                                                ) === 'play'
                                                    ? playToTileProps(content)
                                                    : playbookToTileProps(
                                                          content
                                                      )),
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Frontpage;
